body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
font-weight: normal;
}
.settings-sidebar {
  height: 100%;
  width: 180px;
  position: fixed;
  z-index: 1;
  top: 64.5px;
  left: 260px;
  background-color:white;
  padding-top: 16px;
}
.settings-sidebar-icons{
    width:25%;
    padding-top:20px;
}
.general-text{
  color:grey; 
  font-size:12px;
}
.top-margin{
    margin-top: 10px;
}
.border-settings{
  border:1.5px solid lightgrey;
  border-radius:10px;
  padding:auto;
  width:100px;
  height:90px;
}
.link{
  text-decoration: none !important;
}
.active-settings{
  border:1.5px solid #31197C;
  border-radius:10px;
  padding:auto;
  width:100px;
  height:90px;
}
.text-setting-left{
  text-align: left;
  margin-left:10px;
  margin-top:8px;
}
.main-heading{
margin-left: 1.2rem;
font-size:21px;
font-weight:600;
font-family: Open Sans;
}
.margin-left{
margin-left: 1.2rem;
}
.upi{
  font-size:18px;
  font-weight:600;
  font-family: Open Sans;
}
.recommend{
margin-top:0.5rem;
font-size:15px;
font-weight:600;
font-family: Open Sans;
color:#44444F;
}
.securityalert-img{
width:2%;
}
.securityalert-text{
margin-left:10px;
font-size:14px;
font-weight:600;
font-family: Open Sans;
color:#44444F;  
opacity:0.9;
}
.t-text{
font-size:14px;
letter-spacing: 0.2px;
font-weight:500;
font-family: Open Sans;
color:#44444F; 
opacity:0.5;
}
.display{
display:flex;
}
.out{
color:#31197C;
font-size:14px;
margin-left:2.5rem;
margin-top:-10px;
margin-bottom:10px;
}
.update-btn{
  height:30px;
  width:5rem;
  border-color: #C4C4C4;
  border-radius: 6px;
  font-size: 14px;
  padding:3px;
  color: #ffffff;
  background: #31197C;   
}
.update-btn:hover{
  color: #ffffff;
  background: #31197C;      
}
.discard-btn{
  height:30px;
  width:5rem;
  border-color: #C4C4C4;
  border-radius: 6px;
  font-size: 14px;
  padding:3px;
  color: #A4A4A4;
  background: #ffffff;   
}
.discard-btn:hover{
  color: #A4A4A4;
  background: #ffffff;      
}
input[type="checkbox"].switch1  {
position: absolute;
opacity: 0;
  cursor: pointer;
}
input[type="checkbox"].switch1:checked + div {
background-color: #FF942C;
}
input[type="checkbox"].switch1 + div {
vertical-align: middle;
width: 40px;
height: 20px;
border-radius: 999px;
background-color: lightgrey;
-webkit-transition-duration: .4s;
transition-duration: .4s;
-webkit-transition-property: background-color, box-shadow;
transition-property: background-color, box-shadow;
cursor: pointer;
}

input[type="checkbox"].switch1 + div span {
position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}
input[type="checkbox"].switch1 + div span:nth-child( 1 ) {
  margin-left: 15px;
}

input[type="checkbox"].switch1 + div span:nth-child( 2 ) {
  margin-left: 55px;
} 

input[type="checkbox"].switch1:checked + div {
width: 40px;
background-position: 0 0;
background-color: lightgrey;
}

input[type="checkbox"].switch1 + div {
width: 40px;
height: 20px;
}


input[type="checkbox"].switch1:checked + div {
background-color: #FF942C;
}
input[type="checkbox"].switch1 + div > div {
float: left;
width: 16px; height: 16px;
border-radius: inherit;
background: #ffffff;
-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
transition-timing-function: cubic-bezier(1,0,0,1);
-webkit-transition-duration: 0.4s;
transition-duration: 0.4s;
-webkit-transition-property: transform, background-color;
transition-property: transform, background-color;
pointer-events: none;
margin-top: 2px;
margin-left: 2px;
}

input[type="checkbox"].switch1:checked + div > div {
-webkit-transform: translate3d(10px, 0, 0);
transform: translate3d(10px, 0, 0);
background-color: #ffffff;
}

input[type="checkbox"].switch1:checked + div > div {
-webkit-transform: translate3d(20px, 0, 0);
transform: translate3d(20px, 0, 0);
}
.td-text{
  font-size:12px;
}
.bar-graph-card{
  border:none !important;
}
.quiz-box-z{
  width:500px;
  height:35vh;
  overflow-y: auto;
  font-size: 14px;
  font-family: Open Sans;
  z-index:50;
  position: absolute;
  right:5rem;
  background: white;
  padding: 20px 10px;
  border:1px solid gray;
  border-radius: 5px;
  cursor: pointer;
}
.quiz-box-z-text{
  font-size: 14px;
  font-family: Open Sans; 
}
.bg-fa{
  background-color: white;
  border: 1px solid lightgray;
  width:35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}