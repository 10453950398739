.sidebar {
    height: 100%;
    width: 240px;
    position: fixed;
    z-index: 1;
    top: 64.5px;
    left: 0;
    background-color:white;
    overflow-x: hidden;
    padding-top: 10px;
  }
  .active{
    background-color:#F6F5FF; 
  }
  .downarrow {
    width: 10px;
    height: 10px;
    float: right;
    margin-right: 1.9rem;
    margin-top: 6px;
  }
  .sidebar a {
    padding:5px 4px 5px 40px;
    text-decoration: none;
    font-size: 1.7vh;
    color: black;
    display: block;
  }
  
  .sidebar a:hover {
    color:black;
    background-color: #F6F5FF; 
  }
  .sidebar a >.active {
    color:black;
    background-color: #F6F5FF; 
  }
  .sidebar-icons{
    width:17px;
}
.sidebar-icons-announcement{
  width:20px;
}
.sidebar-icons-audit{
  width:16px;
  height:17px;
}
.finance-icon{
   width:10px;
}
  .text-left{
      margin-left: 10px;
}
.text-left1{
    margin-left: 18px;
}
.bottom-nebutech{
    position:fixed;
    bottom:8px;
    left:25px;
    z-index:200;
}
.nebutech-img{
    width:28%;
}
.md-container {
  padding: 15px;
  text-align: center;
  z-index:100;
}
.modal_new {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index:100;
  overflow: auto;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.6);
}
.modal-content-log {
  background-color:white;
  border: 0.2px solid #D3D3D3;
  border-radius:18px;
  margin:auto;
  font-size:16px;
  font-weight:600;
  width: 20%;
}
.cancelbutton, .deletebutton {
  width: 6rem;
  height:33px;
  color:#31197C;
  font-weight:600;
  font-size:14px;
  border:1px solid #31197C;
  padding-bottom: 5px;
  padding: 5px;
  border-radius:10px;
}
.cancelbutton:hover, .deletebutton:hover {
  width:6rem;
  height:33px;
  font-weight:600;
  font-size:14px;
  color:white;
  border:1px solid #31197C;
  background-color: #31197C;
  padding-bottom: 5px;
  padding: 5px;
  border-radius:10px;
}
.cancelbutton {
  cursor: pointer;
}
.deletebutton {
  cursor: pointer;
}
.logouticon1{
  width:20%;
}
  /* .color{
      color: black;
  }
 .sidebar-display{
    display: flex;
}
 .sidebar-text{
     display: flex;
     margin-left:10px;
 }
 .dashboard-icons{
     width:18px;
 }
 .finance-icon{
    width:10px;
}
 .text-left{
     margin-left: 6px;
     margin-top:-8px;
 }
 .text-left1{
    margin-left: 9.5px;
    margin-top:-8px;
}
  */