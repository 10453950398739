* {
  outline: none;
}

.background {
  background-color: #F6F5FF;
  width: 100%;
  height: 100%;
}

body {
  background-color: #F6F5FF;
}

.logo-width {
  width: 30%;
  height: 30%;
}

.login {
  margin-left: 3.1rem;
  font-size: 17px;
  font-weight: 600;
  color: #333333;
  font-style: Open Sans !important;
}

.login-sm {
  margin-top: 5px;
  margin-left: 3.1rem;
  font-size: 15px;
  color: grey;
  font-style: Open Sans !important;
}

.log {
  width: 350px;
  height: auto;
  margin: 5% auto;
  background-color: #FFF;
  padding: 30px 0;
  border-radius: 20px;
}

h2 {
  color: #31197c;
  text-align: center;
  margin-bottom: 60px;
}

h2:before {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  background: white;
  top: 70px;
  left: 10%;
}

.input-border {
  width: 80%;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 30px;
}

.input-checkbox {
  width: 80%;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 0px;
}

.text {
  width: 80%;
  height: 100%;
  padding: 0 10px;
  border: none;
  border-bottom: 1px solid silver;
  background: none;
  font-size: 14px;
  color: #31197C;
}

.border {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 0;
  height: 2px;
  background: #31197C;
  transition: .5s;
}

.text:focus~.border,
.text:valid~.border {
  width: 100%;
  color: #31197C;
  border-bottom: 1px #31197C;
}

.text:hover {
  border-bottom: 1px #31197C;
}

.label {
  position: absolute;
  top: 8px;
  left: 14px;
  color: silver;
  pointer-events: none;
  font-size: 15px;
  transition: .5s;
}


.text:focus~.label,
.text:valid~.label {
  top: -12px;
  left: 10px;
  color: #31197C;
  font-size: 14px;
}

.btn-login {
  display: block;
  margin: -1rem auto 20px;
  border: 2px solid transparent;
  background-color: #31197C;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  width: 7.4rem;
  height: 40px;
  float: right;
  margin-right: 30px;
}

.btn-login:hover {
  border: 2px solid transparent;
  background-color: #31197C;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

.forgot {
  float: right !important;
  margin-right: 30px;
  text-decoration: none;
  font-size: 14px;
  color: #31197C;
}

.me {
  margin-left: 7px;
  margin-top: -8px;
  color: #31197C;
}

.remember-left {
  margin-left: 2px;
  color: silver;
  font-size: 14px;
}

.label-check {
  margin-left: 40px;
}

.forgot-right {
  float: right;
  margin-right: 40px;
  color: #31197C;
  font-size: 14px;
}

.forgot-right:hover {
  float: right;
  margin-right: 40px;
  color: #31197C;
  font-size: 14px;
}

/* .forgot-password{
margin-top:-3.8rem;
text-decoration: none;
color: #31197C;
} */
.display {
  display: flex;
}

.nebutech {
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 15%;
}

.nebutech-login-img {
  width: 80%;
}

.login-top {
  margin-bottom: 10px;
}

.color-grey {
  color: grey;
  font-family: Open Sans;
}

.google {
  width: 5%;
  margin-left: 10px;
}

.reset-btn {
  width: 80%;
  height: 2.6rem;
  color: white;
  border-radius: 8px;
  background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.reset-btn:hover {
  width: 80%;
  height: 2.6rem;
  color: white;
  border-radius: 8px;
  background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.try {
  color: #4099FF;
  cursor: pointer;
}

.errormsg {
  color: red;
  font-size: 0.625em;
  float: center;
}

.verify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.digit-width {
  width: 40px;
  height: 40px;
  color: black;
  border-radius: 6px;
  height: 3.2rem;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
  font-size: 25px;
}

.forgotImg {
  width: 150px;
  height: 120px;
}

.showPassword {
  position: sticky;
  float: right;
  margin-top: -2.4rem;
  padding: 5px;
  margin-right: 0.5rem;
}

.error-login {
  font-size: 12px;
  color: red;
  margin-top: -15px;
}