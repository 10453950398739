.settings-sidebar {
    height: 100%;
    width: 180px;
    position: fixed;
    z-index: 1;
    top: 64.5px;
    left: 260px;
    background-color:white;
    padding-top: 16px;
  }
  .settings-sidebar-icons{
      width:25%;
      padding-top:20px;
  }
  .general-text{
    color:grey; 
    font-size:12px;
  }
  .top-margin{
      margin-top: 10px;
  }
  .border-settings{
    border:1.5px solid lightgrey;
    border-radius:10px;
    padding:auto;
    width:100px;
    height:90px;
  }
  .link{
    text-decoration: none !important;
  }
  .active-settings{
    border:1.5px solid #31197C;
    border-radius:10px;
    padding:auto;
    width:100px;
    height:90px;
}
.text-setting-left{
    text-align: left;
    margin-left:10px;
    margin-top:8px;
}


.notification-heading{
  margin-top:1rem;
  margin-left: 1.6rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #44444F;
  }
.notifications-sm{
  margin-top:1rem;
  margin-left: 1.6rem;
  margin-right: 0.5rem;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  letter-spacing: 0.4px;
  }
.float-right{
  float:right;
  margin-right:12rem;
  margin-top:0.2rem;  
}
.label-text{
  margin-left:1.6rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
}
.notification-b-line{
  margin-left: 1.6rem;
  width:67%;
}