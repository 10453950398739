
 /* .log {
    width: 400px;
    height:430px;
    margin: 3% auto;
    background-color: #FFF;
    padding: 30px 0;
    border-radius: 20px;
  } */
  .agree{
    color:#31197C;
}
.div{
  margin-top:3px;
}
.flex{
    display: flex !important;
    margin-left: 3rem;
    font-size:12px;
}
.btn-register {
    display: block;
    margin: -1rem 9% 20px;
    border: 2px solid transparent; 
    background-color: #31197C;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    color: white;
    width:7.4rem;
    height:40px;
    float:right;
  }
  .btn-register:hover {
    border: 2px solid transparent; 
    background-color: #31197C;
    border-radius: 10px;
    cursor: pointer;
    color: white;
  }
.logo-width{
      width:25%;
  }
  .login{
      margin-left:3.1rem;
      font-size:17px;
      font-weight:600;
      color:#333333;
      font-style:Open Sans !important;
  }
  .login-sm{
    margin-top:5px;
    margin-left:3.1rem;
    font-size:15px;
    color:grey;
    font-style:Open Sans !important;
}
  .log {
    width: 350px;
    height:auto;
    margin: 5% auto;
    background-color: #FFF;
    padding: 30px 0;
    border-radius: 20px;
  }
    h2{
    color: #31197c;
    text-align: center;
    margin-bottom: 60px;
    }
    
    h2:before{
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background: white;
    top: 70px;
    left: 10%;
    }
    
    .input-border{
    width: 80%;
    height: 30px;
    margin-left:auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 30px;
    }
    
    .text{
    width: 95%;
    height: 100%;
    padding: 0 10px;
    border: none;
    border-bottom: 1px solid silver;
    background: none;
    font-size: 14px;
    color: #31197C;
    }
    
    .border{
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 0;
    height: 2px;
    background: #31197C;
    transition: .5s;
    }
    
    .text:focus ~ .border,
    .text:valid ~ .border{
    width: 100%;
    color:#31197C;
    border-bottom:1px #31197C;
    }
    .text:hover{
      border-bottom:1px #31197C;
    }
    
    .label{
    position: absolute;
    top: 8px;
    left: 14px;
    color: silver;
    pointer-events: none;
    font-size: 15px;
    transition: .5s;
    }

    
    .text:focus ~ .label,
    .text:valid ~ .label{
    top: -12px;
    left: 10px;
    color: #31197C;
    font-size: 14px;
    }
    .forgot{
        float:right;
        margin-right:30px;
        text-decoration: none;
    }
.me{
    margin-left: 7px;
    margin-top: -8px;
}
.forgot-password{
margin-top:-3.8rem;
text-decoration: none;
}
.error {
  color:red;
  font-size: 0.625em;
  float: left;
}
    
  