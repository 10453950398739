.review-heading{
    font-family: Open Sans;
    font-weight:bold;
    font-size:12px;
    width:15%;
}
.review-heading1{
    font-family: Open Sans;
    font-weight:bold;
    font-size:12px;
    width:22%;
}
.review-text{
    font-family: Open Sans;
    font-weight:500;
    font-size:11px;
}
.review-text-{
    font-family: Open Sans;
    font-weight:normal;
    font-size:14px;
}
.fastar{
    color:#FF942C;
    padding-right:50px;
    display:flex;
}
.font-weight{
    font-weight:600;
    font-size:15px;
    font-family: Open Sans;
    color:#FF942C;
}
.prev-btn {
    display: block;
    width:5rem;
    margin: auto ;
    border: 2px solid #FF942C; 
    background-color: white;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    color: #FF942C;
    width:auto;
    height:38px;
    float:right;
  }
  .prev-btn:hover {
      color:#FF942C;
      border: 2px solid #FF942C;
  }
  .next-btn {
    display: block;
    width:5rem;
    margin: auto ;
    margin-right:3.2rem;
    border: 2px solid #FF942C;; 
    background-color: white;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    color: #FF942C;
    width:auto;
    height:38px;
    float:right;
  }
  .next-btn:hover {
    color:#FF942C;
    border: 2px solid #FF942C;
}
.published-hr{
    width:145px;
    border:1.5px solid #31197C;
    color:#31197C;
}
.published-hr-adv{
    width:66px;
    border:1.5px solid #31197C;
    color:#31197C;
}