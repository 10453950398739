.profile-Text-align{
    margin-left: -40px;
    margin-top:2px;
}
.author-Heading{
color:#31197C;
font-size: 20px;
font-weight: 600;
font-family: Open Sans;
}
.fa-star{
    color:#FFBB00;
}
    .card-width-sm{
    background: white;
    height:2.1rem;
    width:45%;
    border-radius: 16px !important;
    }
    .numbers{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin:8px auto 8px auto;
    color: #31197C !important;
    }
    .edit-profile_btn{
        height:33px;
        width:7rem;
        border-color: #FF942C;
        border-radius: 15px;
        font-size: 14px;
        padding:4px 4px 2px 6px;
        color: white;
        background: #FF942C; 
        margin-left:17px;  
        margin-top:-1px;
      }
      .edit-profile_btn:hover{
        color: white;
        background: #FF942C;      
      }
      .card-about-width-sm{
        background: white;
        height:auto;
        width:45%;
        border-radius: 10px;
        padding:20px 20px;
        }
        .about-me{
            color:#393939;
            font-size: 20px;
            font-weight: 500;
            font-family: Open Sans;
        }
        .contact-img-width{
            width:36px;
        }
        .email-img-width{
            width:36px;
        }
        .phone-text{
            color:#A1A1A1;
            font-size:12px;
            font-weight: 500;
            font-family: Open Sans;
        }
        .email-text-wt{
            font-weight: 500;
            font-family: Open Sans; 
            font-size:13px;
        }
        .-container {
            display: grid;
            grid-template-columns: 130px 130px 130px;
            grid-gap: 15px;
            width:42%;
            height:auto;
            background-color: white;
            margin-left: 15px;
          }
          /* .container-width{
            width: 80%;
            height:0px;
            margin: 5% auto;
            background-color: white;
            padding: 10px;
            border-radius: 20px;   
          } */
          .item {
            color: black;
            padding: 0.5em;
            font-size: 14px;
          }
          .a {
            background:white;
          }
          .followers-card{
              width:80%;
              padding:20px 20px 5px 20px;
              border:none;
              border-radius: 12px;
          }
          .followers-Text{
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            margin:8px auto 8px auto;
            color:#393939;
            }
            .followers-Text:hover{
            color:#31197C;
                }
            .profile-active{
                color:#31197C !important;
                border-bottom: 2px solid #31197C;
            }