.inbox {
    font-size: 18px;
    font-family: Open Sans;
    font-weight: 600;
    margin: 5px 20px 20px 20px;
    color: #FF942C;
}

.inbox-r {
    font-size: 20px;
    font-family: Open Sans;
    font-weight: 600;
    margin: 20px;
    color: rgb(48, 47, 47);
}

.inbox_Card {
    border: none;
    padding: 10px 0 10px 10px;
    width: 100%;
    color: black;
    border-radius: 15px;
    background-color: white;
}

.inbox_Card:hover {
    border: none;
    padding: 10px 0 10px 10px;
    width: 100%;
    color: white !important;
    border-radius: 15px;
    background-color: #31197C;
}

._Active {
    border: none;
    padding: 10px 0 10px 10px;
    width: 100%;
    color: white !important;
    border-radius: 15px;
    background-color: #31197C;
}

.link {
    color: black;
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
    color: white;
}

.messages {
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 500;
}

.article-approved {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
}

.text-Article {
    font-family: Open Sans;
    font-size: 10px;
}

.time {
    font-family: Open Sans;
    font-size: 8.5px;
    font-weight: 500;
    float: right;
    margin-right: -20px;
}

.review-btn {
    display: block;
    margin: auto 20px;
    border: 2px solid #31197C;
    ;
    background-color: white;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    color: #31197C;
    width: auto;
    height: 38px;
    float: right;
}

.float_Right {
    float: right;
}

.accept-review-btn {
    height: 30px;
    width: 5rem;
    border-color: #31197C;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 0 2px 0;
    color: #31197C;
    background: #ffffff;
}

.accept-review-btn:hover {
    color: #31197C;
    background: #ffffff;
}

.reject-review-btn {
    height: 30px;
    width: 5rem;
    border-color: #31197C;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 0 2px 0;
    color: #ffffff;
    background: #31197C;
}

.reject-review-btn:hover {
    color: #ffffff;
    background: #31197C;
}

/* .scrolling-area-review-queue {
    width: 100%;
    max-height: 345px;
    overflow: auto;
    padding: 1rem;
    background: white;
    direction: ltr;
} */

/* .scrolling-area-review {
    width: 100%;
    max-height: 485px;
    overflow: auto;
    padding: 1rem;
    background: white;
    direction: ltr;
} */

.scrolling-element-inside {
    direction: ltr;
}

.scrolling-area-report {
    width: 100%;
    max-height: 485px;
    overflow: auto;
    padding: 1rem;
    background: white;
    direction: ltr;
}

.scrolling-element-report {
    direction: ltr;
}

.richTextFieldreview {
    position: relative;
    width: 80%;
    height: 14vh;
    left: 40px;
    margin-top: 0.5rem;
    text-align: left;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 15px;
    background-color: white;
    border-radius: 10px;
    z-index: 0;
    font-size: 12px;
}

.error-review {
    color: red;
    font-size: 12px;
}

.richTextFieldSme {
    position: relative;
    width: 80%;
    height: 4rem;
    left: 32px;
    margin-top: 0.5rem;
    text-align: left;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 15px;
    background-color: white;
    border-radius: 10px;
    z-index: 0;
    font-size: 12px;
}