.graph-width {
    width: 380px;
    height: 280px;
    border-radius: 20px;
    margin: 20px 10px 10px 25px;
    background-color: #FFF;
    padding: 10px;
    border-radius: 20px;
}

.total {
    color: #31197C;
    font-size: 34px;
    font-weight: bold;
    margin-left: 35px;
}

.ads {
    font-size: 24px;
    font-weight: bold;
    margin-left: 32px;
}

.increase-count {
    color: #3DD598;
    font-size: 16px;
    font-weight: bold;
    margin-top: -40px;
    margin-left: 140px;
}

.increase-count1 {
    color: #DF3535;
    font-size: 16px;
    font-weight: bold;
    margin-top: -40px;
    margin-left: 140px;
}

.card-sm {
    width: 400px;
    height: auto;
    margin: 5% auto;
    background-color: #FFF;
    padding: 10px;
    border-radius: 20px;
}

.reviewer-icon {
    width: 50px;
}

.main-heading {
    margin-left: 1.2rem;
    font-size: 21px;
    font-weight: 600;
    font-family: Open Sans;
}

.-container {
    display: grid;
    grid-template-columns: 80px 120px 180px 180px 180px 100px;
    grid-gap: 10px;
    background-color: white;
}

.container-width {
    width: 80%;
    height: 0px;
    margin: 5% auto;
    background-color: white;
    padding: 10px;
    border-radius: 20px;
}

.item {
    color: black;
    padding: 0.5em;
    font-size: 14px;
}

.a {
    background: white;
}

.auto-text {
    margin: auto 0 auto 0;
}

.box {
    height: 60px;
    width: 80%;
    border: 1px solid lightgrey;
    border-top: none;
    border-left: none;
    border-right: none;
    display: flex;
    justify-content: space-around;
}

.start {
    justify-content: flex-start;
}

.line {
    border: 1px solid lightgrey;
    border-top: none;
    width: 95%;
    margin: auto;
}

.allow {
    color: #2B9F15;
    font-weight: 500;
    cursor: pointer;
}

.block {
    color: #E81515;
    font-weight: 500;
    cursor: pointer;
}

.sort {
    margin: 0 4.7rem 0 0;
    font-weight: 500;
    cursor: pointer;
}

.article-container {
    display: grid;
    grid-template-columns: 60px 600px 100px 100px;
    grid-gap: 10px;
    background-color: white;
}

.restore {
    color: #b7b7b7;
    ;
    font-weight: 500;
    cursor: pointer;
}

.drop-down {
    float: right;
    margin-right: -30px;
    margin-top: -1.8rem;
}
.trans-btn {
    height: 35px;
    width: auto;
    border-color: #6CCB36;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    padding: 3px;
    color: white;
    background: #6CCB36;
}

.trans-btn:hover {
    color: white;
    background: #6CCB36;
}
.createadv-btn {
    height: 35px;
    width: 6rem;
    border-color: #6CCB36;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    padding: 3px;
    color: white;
    background: #6CCB36;
}

.createadv-btn:hover {
    color: white;
    background: #6CCB36;
}

.editadv-btn {
    height: 35px;
    width: 6rem;
    border-color: #707070;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    padding: 3px;
    color: #3E3E3E;
    background: #E2E2E2;
}

.editadv-btn:hover {
    color: #3E3E3E;
    background: #E2E2E2;
}

.table-border {
    border-radius: 8px;
    width: 95%;
}

.align-center {
    align-items: center;
    justify-content: center;
}

.radio {
    margin-top: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #B4B4B4;
}

.radio-check {
    margin-top: 12px;
    width: 13.5px;
    height: 12.5px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1.5px solid #B4B4B4;
}

input[type="checkbox"].switch1 {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

input[type="checkbox"].switch1:checked+div {
    background-color: #FF942C;
}

input[type="checkbox"].switch1+div {
    vertical-align: middle;
    width: 40px;
    height: 20px;
    border-radius: 999px;
    background-color: lightgrey;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
    -webkit-transition-property: background-color, box-shadow;
    transition-property: background-color, box-shadow;
    cursor: pointer;
}

input[type="checkbox"].switch1+div span {
    position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}

input[type="checkbox"].switch1+div span:nth-child(1) {
    margin-left: 15px;
}

input[type="checkbox"].switch1+div span:nth-child(2) {
    margin-left: 55px;
}

input[type="checkbox"].switch1:checked+div {
    width: 40px;
    background-position: 0 0;
    background-color: lightgrey;
}

input[type="checkbox"].switch1+div {
    width: 40px;
    height: 20px;
}


input[type="checkbox"].switch1:checked+div {
    background-color: #FF942C;
}

input[type="checkbox"].switch1+div>div {
    float: left;
    width: 16px;
    height: 16px;
    border-radius: inherit;
    background: #ffffff;
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: transform, background-color;
    transition-property: transform, background-color;
    pointer-events: none;
    margin-top: 2px;
    margin-left: 2px;
}

input[type="checkbox"].switch1:checked+div>div {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    background-color: #ffffff;
}

input[type="checkbox"].switch1:checked+div>div {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
}

.h-color {
    color: #3E3E3E;
    font-family: Open Sans;
}

.r-color {
    font-family: Open Sans;
}

.camp-next {
    height: 30px;
    width: 5rem;
    border-color: #707070;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    padding: 3px;
    color: #707070;
    background: #ffffff;
}

.camp-next:hover {
    color: #707070;
    background: #ffffff;
}

.camp-done {
    height: 30px;
    width: 5rem;
    border-color: #707070;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    padding: 3px;
    color: #ffffff;
    background: #707070;
}

.camp-done:hover {
    color: #ffffff;
    background: #707070;
}

.margin-left {
    margin-left: 1.2rem;
}

.upi {
    font-size: 18px;
    font-weight: 600;
    font-family: Open Sans;
}

.upi-btn {
    height: 40px;
    width: 7.5rem;
    border-color: #C4C4C4;
    border-radius: 6px;
    font-size: 14px;
    padding: 3px;
    color: #000000;
    background: #ffffff;
}

.upi-btn:hover {
    color: #000000;
    background: #ffffff;
}

.paytm {
    margin-top: -3px;
    width: 30%;
}

.phonepe {
    width: 24%;
}

.see-all {
    color: #31197C;
    font-family: Open Sans;
    font-size: 14px;
    margin: auto 0px auto 14px;
}

.addcard-btn {
    margin-top: 10px;
    height: 38px;
    width: 8.3rem;
    border-color: #31197C;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 0 3.5px 0;
    color: #000000;
    background: #ffffff;
}

.addcard-btn:hover {
    color: #000000;
    background: #ffffff;
}

.display-search {
    display: flex;
    margin-top: -10px;
}

.display {
    display: flex;
}

.display-bar {
    display: flex;
    margin-top: 7px;
}

.savecard-details-btn {
    height: 40px;
    width: 8rem;
    border-color: #6CCB36;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    padding: 3px;
    color: #ffffff;
    background: #6CCB36;
}

.savecard-details-btn:hover {
    color: #ffffff;
    background: #6CCB36;
}

.save-card-text-sm {
    font-size: 10px;
}

.hdfc {
    margin-top: 10px;
    width: 75%;
}

.hdfc-text {
    margin-left: 6px;
    font-size: 15px;
    margin-top: 4px;
}

.savecard-btn {
    margin-top: 10px;
    height: 30px;
    width: 7rem;
    border-color: #FF942C;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 0 1px 0;
    color: #ffffff;
    background: #FF942C;
}

.savecard-btn:hover {
    color: #ffffff;
    background: #FF942C;
}

.savecard-img {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 50%;
}

.recommend {
    margin-top: 0.5rem;
    font-size: 15px;
    font-weight: 600;
    font-family: Open Sans;
    color: #44444F;
}

.securityalert-img {
    width: 2%;
}

.securityalert-text {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: Open Sans;
    color: #44444F;
    opacity: 0.9;
}

.t-text {
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: Open Sans;
    color: #44444F;
    opacity: 0.5;
}

.out {
    color: #31197C;
    font-size: 14px;
    margin-left: 2.5rem;
    margin-top: -10px;
    margin-bottom: 10px;
}

.update-btn {
    height: 30px;
    width: 5rem;
    border-color: #C4C4C4;
    border-radius: 6px;
    font-size: 14px;
    padding: 3px;
    color: #ffffff;
    background: #31197C;
}

.update-btn:hover {
    color: #ffffff;
    background: #31197C;
}

.discard-btn {
    height: 30px;
    width: 5rem;
    border-color: #C4C4C4;
    border-radius: 6px;
    font-size: 14px;
    padding: 3px;
    color: #A4A4A4;
    background: #ffffff;
}

.discard-btn:hover {
    color: #A4A4A4;
    background: #ffffff;
}

.b-radius {
    border-radius: 15px;
    height: 150px;
}

._receive {
    font-size: 18px;
    font-weight: 700;
    font-family: Open Sans;
    letter-spacing: 0px;
}

.today-text {
    color: #323232;
    font-size: 12px;
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: 500;
}

.updated-text {
    color: #323232;
    font-size: 11px;
    opacity: 0.4;
    font-weight: 600;
}

.received-users {
    color: #31197C;
    font-size: 34px;
    font-weight: bold;
    margin-left: 10px;
}

.received-users-green {
    color: #3DD598;
    font-size: 16px;
    font-weight: bold;
    margin-top: 12px;
    float: right;
    margin-right: 25%;
}

.received-users-red {
    color: #DF3535;
    font-size: 16px;
    font-weight: bold;
    margin-top: 12px;
    float: right;
    margin-right: 25%;
}

.br-radius {
    border-radius: 15px;
    height: 324px;
}

.transfer-img {
    width: 18%;
}

.depo-text {
    color: #323232;
    font-size: 14px;
    font-weight: 500;
    margin: -46px 0px auto 56px;
}

.depo-date {
    color: #323232;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.4;
}

.depo-amount {
    color: #31197c;
    font-size: 14px;
    font-weight: 500;
    float: right;
    margin-top: -12px;
}

.depo-amount-red {
    color: #DF3535;
    font-size: 14px;
    font-weight: 500;
    float: right;
    margin-top: -12px;
}

.invoices {
    margin-left: 20px;
    height: 58px;
    border-radius: 15px;
}

.invoice-img {
    width: 35%;
    padding: 10px;
}

.invoice-text {
    color: #323232;
    font-size: 10px;
    margin: -45px 0px auto 56px;
    opacity: 0.7;
    font-family: Open Sans;
}

.invoice-no {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

._table {
    background-color: white;
}

._table:hover {
    background-color: #f2f0f8;
}

.t-heading {
    color: #3E3E3E;
    font-family: Open Sans;
    opacity: 0.4;
    font-size: 12px;
}

.t-row {
    color: #3E3E3E;
    font-family: Open Sans;
    font-size: 12px;
}

.t-row-date {
    color: #3E3E3E;
    font-family: Open Sans;
    font-size: 12px;
    opacity: 0.4;
}

.t-row-servicetype {
    color: #3E3E3E;
    font-family: Open Sans;
    font-size: 12px;
    opacity: 0.4;
}

.t-row-email {
    color: #31197C;
    font-family: Open Sans;
    font-size: 12px;
}

.paid-btn {
    height: 20px;
    width: 3rem;
    border-color: #f2f0f8;
    border-radius: 2px;
    font-size: 12px;
    padding: 0px 2px 2px 2px;
    color: #31197c;
    background: #dcdbdf;
}

.paid-btn:hover {
    color: #31197c;
    background: #dcdbdf;
}

.unpaid-btn {
    height: 20px;
    width: 3rem;
    border-color: #f2f0f8;
    border-radius: 2px;
    font-size: 12px;
    padding: 0px 2px 2px 2px;
    color: #F04343;
    background: #fad7d7;
}

.unpaid-btn:hover {
    color: #F04343;
    background: #dcdbdf;
}

.arrow-color {
    color: #31197c;
}

.arrow-color-red {
    color: #F04343;
}

.border-none {
    color: black;
    text-decoration: none;
}

.border-none:hover {
    color: black;
    text-decoration: none;
}

.left {
    margin-left: 45%;
}

.error {
    color: #db2269;
    font-size: 10px;
    display: relative;
}

.showError {
    border-color: #a94442 !important;
    color: #a94442 !important;
    margin-bottom: 15px;
}

.none {
    border: none;
    height: 35px;
    border-radius: 15px;
}

.create-poll-img {
    width: 10%;
}

.progress-bar {
    background-color: #72BF37;
    ;
    color: white;
}

.progress_bar {
    width: 100%;
    height: 10px;
    border-radius: 20px;

}

.p-bar {
    margin-top: -6px;
    margin-left: 10px;
}

.poll-table-heading {
    color: black;
    font-size: 18px;
    font-weight: 600;
    font-family: Open Sans;
}

.poll-table-text {
    color: black;
    font-size: 14px;
    font-weight: 500;
    font-family: Open Sans;
}

.cursor {
    cursor: pointer;
}

.pollCard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    width: 100%;
    border-radius: 13px;
}

.Table {
    border-collapse: separate;
    border-spacing: 0 1em;
}

#_Search {
    background-image: url('../../src/images/searchwhite.png');
    background-position: 96% 50%;
    background-size: 5%;
    background-repeat: no-repeat;
    width: 30%;
    z-index: 100;
    font-size: 16px;
    padding: 12px 35px 12px 12px;
    border: none;
}

._Search-border {
    border: none;
    border-radius: 10px;
    height: 35px;
}

.article-search {
    float: right;
    margin-top: -2rem;
    margin-right: 2.5rem;
}

#Search_ {
    background-image: url('../../src/images/searchwhite.png');
    background-position: 96% 50%;
    background-size: 8%;
    background-repeat: no-repeat;
    z-index: 100;
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    padding: 12px 32px 12px 12px;
    border: 1px solid lightgrey;
}

.Search_border {
    border: 1px solid lightgrey;
    height: 35px;
}

.float_right {
    float: right;
    margin-right: 30%;
}

.save-poll-btn {
    height: 30px;
    width: 5rem;
    border-color: #31197C;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 0 2px 0;
    color: #31197C;
    background: #ffffff;
}

.save-poll-btn:hover {
    color: #31197C;
    background: #ffffff;
}

.show-poll-details-btn {
    height: 30px;
    width: 9rem;
    border-color: #31197C;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 0 2px 0;
    color: #ffffff;
    background: #31197C;
}

.show-poll-details-btn:hover {
    color: #ffffff;
    background: #31197C;
}

.show-poll-post-btn {
    height: 30px;
    width: 5rem;
    border-color: #31197C;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 0 2px 0;
    color: #ffffff;
    background: #31197C;
}

.show-poll-post-btn:hover {
    color: #ffffff;
    background: #31197C;
}

.div {
    margin-top: 3px;
}

.poll_Topic_Name {
    color: black;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 25px;
}

.poll_Topic {
    color: black;
    font-family: Open Sans;
    font-weight: 400;
    font-size: 25px;
}

.modal-block {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-block-content-log {
    background-color: white;
    border: 0.2px solid #D3D3D3;
    border-radius: 18px;
    margin: auto;
    font-size: 16px;
    font-weight: 600;
    width: 25%;
}

.md-container {
    padding: 15px;
    text-align: center;
    z-index: 100;
}

.modal_new {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-content-log {
    background-color: white;
    border: 0.2px solid #D3D3D3;
    border-radius: 18px;
    margin: auto;
    font-size: 16px;
    font-weight: 600;
    width: 20%;
}

.modifybutton {
    width: 6.5rem;
    height: 33px;
    color: #31197C;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #31197C;
    padding-bottom: 5px;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.up-load{
    font-family: Open sans;
    font-size: 12px;
    cursor: pointer;
}
.up-load-banner{
    font-family: Open sans;
    font-size: 12px;
    cursor: pointer;
}
.inCompleteUser{
    text-align: left;
    font-weight: bold;
    color:#ff942c !important;
}
.stopbutton {
    width: 6.5rem;
    height: 33px;
    color: #D12020;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #D12020;
    padding-bottom: 5px;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

/* .cancelbutton:hover, .deletebutton:hover {
        width:6.5rem;
        height:33px;
        font-weight:600;
        font-size:14px;
        color:white;
        border:1px solid #31197C;
        background-color: #31197C;
        padding-bottom: 5px;
        padding: 5px;
        border-radius:10px;
      } */
.modifybutton:hover {
    cursor: pointer;
    color: #31197C;
}

.stopbutton:hover {
    cursor: pointer;
    color: #D12020;
}

._close {
    margin-top: -0.8rem;
    color: grey;
    float: right;
    font-size: 24px;
}

._close:hover,
._close:focus {
    color: grey;
    text-decoration: none;
    cursor: pointer;
}

.edit {
    width: 18px;
}

.red {
    color: red;
}

.cancel-right {
    float: right;
    margin-right: 20%;
}

.cancel-right-text {
    float: right;
    margin-right: 5%;
}

.active-add-h-text {
    color: #FF942C;
    font-weight: 700;
    font-size: 16px;
}

.scrolling-area-adds {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    padding: 1rem;
    background: white;
}

.scrolling-element-inside-adv {
    direction: ltr;
}

/* thead{
        background-color: white;
        position: fixed;
      } */
/* tbody{
          position:relative;
          top:3rem;
      } */
.tableReportsFixHead {
    overflow-y: auto;
    height: 250px;
}

.tableReportsFixHead thead th {
    position: sticky;
    top: 0;
}

.tableReportsFixHead th {
    background: #31197C;
    color: white;
}
.tableGraph th {
    background: #7d56f5;
    color: white;
}
.tableGraph {
    overflow-y: auto;
    height: 200px;
}
.tablGraph thead th {
    position: sticky;
    top: 0;
}
.tableFixHead {
    overflow-y: auto;
    height: 75vh;
}
.tableFixHead thead th {
    position: sticky;
    top: 0;
}
.tableFixHead th {
    background: #31197C;
    color: white;
}
.tableFixHead-saved {
    overflow-y: auto;
    height: 81vh;
}
.tableFixHead-saved thead th {
    position: sticky;
    top: 0;
}
.tableFixHead-saved th {
    background: #31197C;
    color: white;
}
table {
    border-collapse: collapse;
    width: 97.5%;
}
th,
td {
    padding: 8px 16px;
    border: none;
}
.Red {
    background: red;
}

.green {
    background: #F6F5FF;
}
.tableGraph-article th {
    background: #f5b44c;
    color: white;
}
.tableGraph-article {
    overflow-y: auto;
    height: 98px;
}
.tableGraph-article thead th {
    position: sticky;
    top: 0;
}
.vertical-timeline {
    display: flex;
    flex-direction: column;
  }
  
  .vertical-timeline-item {
    position: relative;
    padding: 20px;
    border-left: 2px solid #ccc;
  }


.modal-article {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 10px;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-article-content {
    background-color: white;
    border: 0.2px solid #D3D3D3;
    border-radius: 10px;
    margin: auto;
    font-size: 16px;
    font-weight: 600;
    width: 22%;
}

.md-article-container {
    padding: 15px;
    text-align: left;
    z-index: 100;
}

.history-active {
    color: #31197C !important;
    border-bottom: 2px solid #31197C;
}

.history-active:hover {
    color: #31197C !important;
}

.Link {
    text-decoration: none !important;
    color: #31197C !important;
}

.current-history-fixed {
    background-color: transparent;
    position: fixed;
    width: 100%;
    top: 3rem;
    margin-top: 20px;
    height: 55px;
    z-index: 100;
}

.history-Text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
}

.view_article {
    position: fixed;
    height: auto;
    width: 20%;
    background: white;
    top: 60px;
    /* color: #FF942C; */
}

.heading-color {
    color: #31197C !important;
}

.article-title-color {
    color: #FF942C;
}

.article-modal-fixed {
    position: fixed;
    right: 40.3%;
    font-size: 20px;
    cursor: pointer;
    float: right;
    background: white;
}
._card{
    padding:5px;
    border:1px solid grey;
    border-radius: 10px;
}
.l-card-title{
    font-family: Open Sans;
    font-weight: 500;
    font-size: 14px; 
}
.l-card-description{
    font-family: Open Sans;
    font-weight: 500;
    font-size: 12px; 
    justify-content: space-between;
}
.l-cards{
    font-family: Open Sans;
    font-weight: 600;
    font-size: 15px;   
}
.create-learning-card-heading{
    margin-top: 3.5rem !important;
    font-weight: 500;
    font-family: open sans;
    font-size: 20px;
    color:#31197C;
}
.l-card-create{
    padding:13px 0px 0px 20px;
    border-radius: 15px;
    border: 1px solid grey;
    width:95%;   
}
.modal-header {
    border-bottom: 0 none;
}
.modal-body {
    position: relative;
    overflow-y: auto;
    padding: 5px;
    margin: 0;
}
/* .modal-content{
    padding: 0;
    margin: 0;
} */
.e-card-create{
    padding:17px 0px 0px 20px;
    border-radius: 15px;
    border: 1px solid grey;
    width:95%;   
}
.l-card-create-height{
    padding:10px;
    border-radius: 15px;
    border: 1px solid grey;  
    height:61vh; 
    overflow: auto;
}
.center-text{
    margin: auto;
    width: 50%;
    border: none;
    padding: 10px;
  }
  .m-box{
    width:10%;
    align-items: center;
    justify-content: center;
    text-align: center;
    position:absolute;
    left:45%;
    right:45%;
  }
  .sequence-hover{
    color:black;
    cursor: pointer;
  }
  .sequence-hover:hover{
    color:#FF942C;
  }
  .hidden{
    display:none
  }
  .index{
    opacity:0.8;
    font-weight: 600;
  }
.grid-wrapper {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
  .f-cir{
    color:#FF942C;
  }
  .pName{
    font-size: 20px;
    font-family: poppins;
  }
  .pDuration{
    font-size: 18px;
    font-family: poppins;
    text-align: right;
    float: right;
  }
  .fourEs_data{
    border-radius: 50%;
    color:white;
    font-size: 35px;
    font-family: 'Open Sans';
  }
  .counts{
    font-size:1.5vw;
    font-family: 'Open Sans';
    font-weight:600;
  }
  .graph-heading-T{
    font-size:13px;
    font-family: 'Open Sans';
    font-weight:600;
  }
  .select-font{
    font-size:11px;
    font-family: 'Open Sans';
    font-weight:500;
  }
  .box-shad{
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
  }
  .dash-card-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .gh-heading {
    font-size: 11px;
    font-weight: 600;
    color:white;
  }
  .gh-text{
    font-size: 10px;
    font-weight: 500;
    color:black; 
  }
  .t-container {
    width: 80%;
    padding: 50px 0;
    margin: 50px auto;
    position: relative;
    overflow: hidden;
 }
 
 .t-container:before {
    content: '';
    position: absolute;
    top: 10%;
    left: 50%;
    margin-left: -2.5px;
    width: 3px;
    height: 100%;
    background: #ff942c;
    z-index: 1
 }
 
 .timeline-block {
    width: -webkit-calc(50% + 8px);
    width: -moz-calc(50% + 8px);
    width: calc(50% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
 }
 
 .timeline-block-right {
    float: right;
 }
 
 .timeline-block-left {
    float: left;
    direction: rtl
 }
 
 .marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #F5F7FA;
    background: #31197c;
    margin-top: 10px;
    z-index: 9999
 }
 
 .timeline-content {
    width: 100%;
    padding: 0 15px;
    color: black;
 }
 
 .timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500
 }
 
 .timeline-content span {
    font-size: 13px;
    color: black;
 }
 
 /* .timeline-content p {
    font-size: 14px;
    line-height: 1.5em;
    word-spacing: 1px;
 } */
 .timeline-text{
    font-size: 12px;
    font-family: "Open Sans";
    font-weight:normal;
    color:black;
    margin-top: -15px;
 }
 .time-img{
    width:30px;
    margin-bottom:5px;
 }
 .time-user-img{
    width:20px;
    margin-bottom:5px;
 }
 .time-article-img{
    width:25px;
    margin-bottom:5px;
 }
 .time-reward-img{
    width:50px;
    margin-bottom: -5px;
 }
 .newFText-btn{
    width: 5rem;
    color: white;
    margin-right: 6px;
    font-weight:600;
    font-family: Open Sans !important;
    font-size: 13px;
 }
 .newFText-btn:hover{
    width: 5rem;
    color: white;
    font-weight:600;
    margin-right: 6px;
    font-family: Open Sans !important;
    font-size: 13px;
 }
