.text-box-radius {
  border-radius: 10px;
  height: 30px;
  font-size: 14px;
}

.description-radius {
  border-radius: 12px;
  border: none;
}

.richTextFieldview {
  position: relative;
  width: 100%;
  height: 41vh;
  left: 0;
  margin-top: 1rem;
  text-align: left;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: #ecf0f0;
  border-radius: 10px;
  font-size: 14px;
}

.boldview {
  display: flex;
  margin: auto 2px;
  width: 30px;
  height: 30px;
  border: 1px solid #ecf0f0;
  background-color: #ecf0f0;
  border-radius: 8px;
  padding: 2.5px 9px;
  font-size: 16px;
  cursor: pointer;
  color: #31197C;

}

.boldIview {
  display: flex;
  margin: auto 2px;
  width: 30px;
  height: 30px;
  border: 1px solid #ecf0f0;
  background-color: #ecf0f0;
  border-radius: 8px;
  padding: 2.5px 12px;
  font-size: 16px;
  cursor: pointer;
  color: #31197C;

}

.overflow {
  overflow-y: scroll;
}

.image_topicId {
  height: 95px;
  width: 50%;
}

#editor {
  height: 46vh;
  background-color: white;
  padding: 8px 2px 1px 2px;
  border-radius: 6px;
}

.ql-toolbar {
  text-align: right;
  border-radius: 6px;
  border: none !important;
}

.split-content {
  margin-bottom: -2rem;
  margin-left: 2px;
}

.ql-container {
  border-radius: 6px;
}

.ql-editor {
  border: 1px solid #ced4da;
  border-radius: 6px;
}

.ql-snow .ql-editor h1,
.article-content h1,
.quill-size h1 {
  font-size: 1.25rem !important;
  color: #31197c;
}

.quill-size {
  margin: auto;
}

/* .ql-editor h1 {   font-size: 10px; } */
.richTextField {
  position: relative;
  width: 100%;
  height: 40vh;
  left: 0;
  margin-top: 1rem;
  text-align: left;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: white;
  border-radius: 10px;
  z-index: 0;
  font-size: 14px;
}

.richTextField-c {
  position: relative;
  width: 100%;
  height: 39vh;
  left: 0;
  text-align: left;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid lightgrey;
  z-index: 0;
  font-size: 14px;
}

.richTextField_card {
  position: relative;
  width: 100%;
  height: 15vh;
  left: 0;
  margin-top: 1rem;
  text-align: left;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid lightgrey;
  z-index: 0;
  font-size: 14px;
}

.richTextField_feature {
  position: relative;
  width: 100%;
  height: 15vh;
  left: 0;
  text-align: left;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid lightgrey;
  z-index: 0;
  font-size: 14px;
}

.create-imageurl {
  margin-top: -5px;
  width: 90px;
  height: 86px;
  border-radius: 10px;
}

.edit-imageurl {
  margin-top: -5px;
  width: 90px;
  height: 86px;
  border-radius: 10px;
}

.border-url {
  border: 1.5px solid #31197c !important;
  height: 86px !important;
  width: 90px !important;
  border-radius: 10px !important;
}

.richTextField-read {
  position: relative;
  width: 100%;
  height: 43vh;
  left: 0;
  margin-top: 1rem;
  text-align: left;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: #e9ecef;
  border-radius: 10px;
  z-index: 0;
  border: 1px solid lightgrey;
  font-size: 14px;
}

.bold {
  /* font-weight: 600;
  font-size:25px;
  padding:5px 15px 5px 15px;
  cursor:pointer;
  float:right;
  border: 1px light grey; */
  display: flex;
  margin: auto 2px;
  width: 30px;
  height: 30px;
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 8px;
  padding: 2.5px 9px;
  font-size: 16px;
  cursor: pointer;
  color: #31197C;

}

.boldI {
  display: flex;
  margin: auto 2px;
  width: 30px;
  height: 30px;
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 8px;
  padding: 2.5px 12px;
  font-size: 16px;
  cursor: pointer;
  color: #31197C;

}

.cancelreview-btn {
  display: block;
  margin: -1rem auto 20px;
  border: 2px solid #FF942C;
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  color: #FF942C;
  width: 8rem;
  height: 38px;
  float: right;
}

.cancelreview-btn:hover {
  border: 2px solid #FF942C;
  color: #FF942C;
  display: block;
  margin: -1rem auto 20px;
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: right;
}

.cancelreview-btn_ {
  margin: -1rem auto 20px;
  border: 2px solid #FF942C;
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  color: #FF942C;
  width: 8rem;
  height: 38px;
  float: right;
}

.cancelreview-btn_:hover {
  border: 2px solid #FF942C;
  color: #FF942C;
  margin: -1rem auto 20px;
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: right;
}

.close-btn-popup {
  display: block;
  margin: auto;
  border: 2px solid #FF942C;
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  color: #FF942C;
  width: 8rem;
  height: 38px;
  float: left;
}

.close-btn-popup:hover {
  border: 2px solid #FF942C;
  color: #FF942C;
  display: block;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: left;
}

.sendreview-btn {
  display: block;
  margin: -1rem auto 20px;
  border: 2px solid transparent;
  background-color: #31197C;
  border-radius: 10px;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: right;
}

.sendreview-btn:hover {
  display: block;
  margin: -1rem auto 20px;
  border: 2px solid transparent;
  background-color: #31197C;
  border-radius: 10px;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: right;
}

.savelearncards-btn {
  display: block;
  margin: -1rem auto;
  border: 2px solid transparent;
  background-color: #31197C;
  border-radius: 10px;
  color: white;
  padding: 5px 5px;
  font-size: 13.5px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: left;
}

.savelearncards-btn:hover {
  display: block;
  margin: -1rem auto;
  border: 2px solid transparent;
  background-color: #31197C;
  border-radius: 10px;
  color: white;
  padding: 5px 5px;
  font-size: 13.5px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: left;
}

.publish-btn {
  display: block;
  border: 2px solid transparent;
  background-color: #31197C;
  border-radius: 10px;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  position: absolute;
  bottom: 1rem;
  right: 0.7rem;
}

.publish-btn:hover {
  display: block;
  border: 2px solid transparent;
  background-color: #31197C;
  border-radius: 10px;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  position: absolute;
  bottom: 1rem;
  right: 0.7rem;
}

.sendreview-btn-new {
  display: block;
  margin: -1rem auto 20px;
  border: 2px solid #FF942C;
  background-color: white;
  border-radius: 10px;
  color: #FF942C;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: right;
}

.sendreview-btn-new:hover {
  display: block;
  margin: -1rem auto 20px;
  border: 2px solid #ff942c;
  background-color: white;
  border-radius: 10px;
  color: #ff942c;
  ;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 8rem;
  height: 38px;
  float: right;
}

.save-btn {
  display: block;
  margin: -1rem auto 20px;
  border: 2px solid #31197C;
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  color: #31197C;
  width: 8rem;
  height: 38px;
  float: right;
}

.save-btn:hover {
  display: block;
  margin: -1rem auto 20px;
  border: 2px solid #31197C;
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  color: #31197C;
  width: 8rem;
  height: 38px;
  float: right;
}

.article-title {
  font-size: 18px;
  font-family: Open Sans;
  font-weight: 600;
}

.article-desc {
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 490;
}

.article-content {
  font-size: 14px;
  font-family: Raleway, sans-serif;
}

#editor1 {
  border: 3px inset grey;
  height: 100px;
  width: 381px;
  margin: 10px auto 0;
}

fieldset {
  margin: 2px auto 15px;
  width: 358px;
}

button {
  width: 5ex;
  text-align: center;
  padding: 1px 3px;
}

div.container {
  width: 300px;
  height: 100px;
  border: 1px solid #ccc;
  padding: 5px;
}

.-bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  width: 300px;
  height: 180px;
  margin: auto;
  padding: 40px;
  background: white;
  border-radius: 12px;
  ;
}

.reviewer-modal {
  position: fixed;
  display: none;
  z-index: 1;
  left: 0;
  top: -4px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
}

.reviewer-modal-content-log {
  background-color: white;
  border: 0.2px solid #D3D3D3;
  border-radius: 18px;
  margin: auto;
  font-size: 15px;
  width: 58%;
}

.reviewer_container {
  padding: 10px;
  text-align: center;
}

.close-icon-new {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.post_q {
  border: 1px solid #31197c;
  border-radius: 10px;
  padding: 5px 10px;
}

.scrolling-area {
  width: 19vw;
  height: 86vh;
  max-height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  background: white;
  direction: ltr;
}

.scrolling-element-inside {
  direction: ltr;
}

._rolebtn {
  background-color: #31197C;
  width: 4.5rem;
  height: 25px;
  border-color: #31197C;
  border-radius: 4px;
  text-align: center;
  margin: auto;
  padding-top: 2.5px;
  color: white;
  font-size: 12px;
  font-weight: 500;
}

._rolebtn:hover {
  background-color: #31197C;
  border-color: #31197C;
  color: white;
}

.tooltip {
  background-color: #ecf0f0;
  color: black;
}

.th-heading {
  font-size: 14px;
  font-weight: 600;
}

.write-article-img {
  width: 100%;
  height: 100%;
}

.poll-answer-card-post {
  padding: 0.5rem 0.5rem 1rem 0.7rem;
  position: relative;
  left: 0;
  height: auto;
  width: 98%;
  background: #ebfcfc;
  border-radius: 20px;
  margin: 0;
}

.polloptions-card-post {
  margin-top: 1rem;
  width: 98%;
  font-size: 13px;
  background: white;
  height: 50px;
  display: flex;
  padding: 2px 5px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.poll-text {
  font-size: 13px;
  font-family: Open Sans !important;
  align-items: flex-start;
  float: left;
  font-weight: 600;
  justify-content: left;
  margin-top: auto;
  margin-bottom: auto;
}

.question_post {
  font-size: 14px;
}