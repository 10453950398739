#_Search {
    background-image: url('../../src/images/searchwhite.png');
    background-position: 96% 50%;
    background-size: 5%;
    background-repeat: no-repeat;
    width: 30%;
    z-index:100;
    font-size: 16px;
    padding: 12px 35px 12px 12px;
    border: none;
  }
  /* .font-heading-web{
    font-weight:600;
  } */
  ._Search-border{
    border:none;
    border-radius:10px;
    height:35px;
  }
  .none{
    border:none; 
    height:35px; 
    border-radius: 15px; 
   }
   .create-poll-img{
    width:10%;
}
.error {
    color:#db2269;
    font-size: 10px;
    display: relative;
  }
.Table {
    border-collapse: separate;
    border-spacing: 0 1em;
}
.progress-bar {
    background-color:#72BF37;;
    color: white;
  }
.progress_bar{
    width:100%;
    height:10px;
    border-radius: 20px; 
}
.p-bar{
    margin-top:-6px;
    margin-left:10px;
}
.poll-link{
  color:black;
}
.poll-link:hover{
  text-decoration: none !important;
  color:black;
}
.poll-table-heading{
    color: black;
    font-size:16px;
    font-weight:600;
    font-family: Open Sans;
}
.poll-table-text{
  color: black;
  font-size:14px;
  font-weight:500;
  font-family: Open Sans;
}
.cursor{
  cursor: pointer;
}
.pollCard{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  transition: 0.3s;
  width: 100%;
  border-radius:13px;
}
.float_right{
    float:right;
    margin-right: 30%;
}
.save-poll-btn{
  height:30px;
  width:5rem;
  border-color: #31197C;
  border-radius: 6px;
  font-size: 14px;
  padding:0 0 2px 0;
  color: #31197C;
  background: #ffffff;   
}
.save-poll-btn:hover{
  color: #31197C;
  background: #ffffff;      
}
.float_right_poll{
  float:right;
  margin-right: 28%;
  margin-top:10px;
}
.float_right_offer{
  float:right;
  margin-right: 3%;
  margin-top:10px;
}
.show-poll-details-btn{
  height:30px;
  width:9rem;
  border-color: #31197C;
  border-radius: 6px;
  font-size: 14px;
  padding:3px 0 0 0;
  color: #ffffff;
  background: #31197C;   
}
.show-poll-details-btn:hover{
  color: #ffffff;
  background: #31197C;      
}
.show-poll-post-btn{
  height:30px;
  width:5rem;
  border-color: #31197C;
  border-radius: 6px;
  font-size: 14px;
  padding:0 0 2px 0;
  color: #ffffff;
  background: #31197C;   
}
.show-poll-post-btn:hover{
  color: #ffffff;
  background: #31197C;      
}
.display-bar{
    display:flex;
    margin-top:7px;
}
.display{
    display:flex;
}
.edit{
    width:18px;
}
.div{
    margin-top:3px;
  }
      
      .md-container {
        padding: 15px;
        text-align: center;
        z-index:100;
      }
      .modal_new {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index:100;
        overflow: auto;
        background-color: rgb(0,0,0); 
        background-color: rgba(0,0,0,0.6);
      }
      .modal-content-log {
        background-color:white;
        border: 0.2px solid #D3D3D3;
        border-radius:18px;
        margin:auto;
        font-size:16px;
        font-weight:600;
        width: 20%;
      }
      .modifybutton {
        width: 6.5rem;
        height:33px;
        color:#31197C;
        font-weight:600;
        font-size:14px;
        border:1px solid #31197C;
        padding-bottom: 5px;
        padding: 5px;
        border-radius:10px;
        cursor: pointer;
      }
      .stopbutton{
        width: 6.5rem;
        height:33px;
        color:#D12020;
        font-weight:600;
        font-size:14px;
        border:1px solid #D12020;
        padding-bottom: 5px;
        padding: 5px;
        border-radius:10px;
        cursor: pointer;  
      }
      /* .cancelbutton:hover, .deletebutton:hover {
        width:6.5rem;
        height:33px;
        font-weight:600;
        font-size:14px;
        color:white;
        border:1px solid #31197C;
        background-color: #31197C;
        padding-bottom: 5px;
        padding: 5px;
        border-radius:10px;
      } */
      .modifybutton:hover {
        cursor: pointer;
        color:#31197C;
      }
      .stopbutton:hover {
        cursor: pointer;
        color:#D12020;
      }
      ._close {
        margin-top:-0.8rem;
        color: grey;
        float: right;
        font-size: 24px;
      }
      
      ._close:hover,
      ._close:focus {
        color: grey;
        text-decoration: none;
        cursor: pointer;
      }
      .b {
        background:#f8f4fc;
      }
      .b,b:hover{
          text-decoration: none !important;
          color:black;
      }
      .poll-card{
        padding:2rem, 3rem;
        height:auto;
        width:95%;
        background-color: white;
        border-radius:20px;
        margin: 0 auto 0 auto;
      }
      .answers-card{
        height:auto;
        margin-left:auto;
        margin-right: auto;
      }
      .web-font{
        font-size: 12px;
      }
      .poll-date{
        font-size:14px;
      }
      .topic{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.8px;
        color: black;
      }
      .topic-name{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.8px;
        color: black;
        text-align: left;
      }
      .poll-question{
        padding:0.5rem;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        letter-spacing: 0.2px;
        color: #000000;
      }
      .scrolling-area-polls {
        width: 100%;
        max-height: 68vh;
        overflow: auto;
        padding: 1rem;
        background: white;
        direction: ltr;
      }
      .scrolling-element-inside-poll {
        direction: ltr;
      }