.navbar-logo {
    margin-left: 2.5rem;
    cursor: pointer;
    margin-top: -2px;
    width: 30%;
}

.nav-height {
    height: 4rem;
    background-color: #31197c;
}

.search_box {
    padding-top: -20px;
    width: 300px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 20px;
}

#search {
    background-image: url('../../src/images/searchwhite.png');
    background-position: 96% 50%;
    background-size: 6%;
    background-repeat: no-repeat;
    z-index: 100;
    margin-left: 60px;
    padding-top: -20px;
    width: 300px;
    height: 35px;
    border: 1px solid grey;
    border-radius: 20px;
}

.mr-right {
    margin-right: 2rem;
}

.icon {
    width: 20px;
}

._authorbtn {
    background-color: #31197c;
    width: 4.5rem;
    height: 25px;
    border-color: white;
    border-radius: 4px;
    text-align: center;
    margin: auto;
    padding-top: 3px;
    color: white;
    font-size: 12px;
    font-weight: 500;
}

._authorbtn:hover {
    background-color: #31197c;
    border-color: white;
    color: white;
}

/* 
.navbar-logo{
    margin-left: 3rem;
    cursor:pointer;
    width:8%;
    margin-bottom: -3.0rem;
    margin-top:0.3rem;
}
.nav-height{
    height:3rem;
    background-color: #31197c;
}
.nav-menu{
    display: grid;
    grid-template-columns:repeat(6,auto);
    grid-gap:10px;
    padding: 10px;
    list-style:none;
    text-align:center;
    text-decoration: none;
    width:100vw;
    justify-content:end;
    margin-right: 2 rem;
    margin-top:-.5rem;
    font-size: small;
}
.nav-links{
    color: black; 
    transition:all 0.2s ease-out;
    padding: 1.2rem;
}
 .nav-marginright{
    margin-right:31.5rem ;
 }
 .nav-marginright1{
    margin-right:35rem ;
 }
.nav-links-loginbtn{
    background-color: #BDA083; 
    width: 6.7rem;
    height: 25px;
    border-color: #BDA083;
    border-radius: 30px;
    text-align: center;
    margin:auto;
    margin-left:2.2rem;
    padding-top:1px;
    color:white;
    font-size: small;
}
.btn-jelly{
    background-color: #4FD84F; 
    border-color: 5px solid black;
    font-size: small;
}
.btn-login{
    background-color: #BDA083; 
    border-color:#BDA083 ;
    color:white;
    font-size: small;
}
.nav-links-jellybtn{
    background-color: #4FD84F; 
    width: 6.7rem;
    height: 30px;
    border-color:10px solid black;
    border-radius: 30px;
    text-align: center;
    margin:auto;
    margin-right:2rem;
    color:white;
    padding-top:3px;
}
.nav-links-jellybtn:hover{
    background-color: #4FD84F; 
    border-color: 10px solid black;   
    color:white;
}
.nav-links-loginbtn:hover{
    background-color: #BDA083; 
    border-color:#BDA083 ;
    color:white;
}
.nav-links:hover{
    color: #4FD84F; 
    transition:all 0.2s ease-out;
    text-decoration: none;
}
.fa-bars{
    color:black;
    margin-top:-0.5rem;
}
.menu-icon{
    display:none;
}
@media screen and (max-width:960px){
    .NavBarItems{
        position: relative;
    }
    .nav-menu{
        display: flex;
        flex-direction:column;
        width:100%;
        height:500px;
        position: absolute;
        top:50px;
        left:-100%;
        opacity:1;
        transition: all 0.5s ease;
    }
    .nav-menu.active{
        background-color: rgb(204, 200, 200);
        left:0;
        top:165px;
        height:22rem;
        opacity: 1;
        transition: all 0.5s ease;
        z-index:1;
    }
    .nav-links{
        text-align:center;
        padding: 0.5rem;
        width:100%;
        display:table;
    }
    .navbar-logo{
        position:absolute;
        left:-3.5rem;
        margin-top: -1.5rem;
        transform:translate(25%,50%);
        width:7%;
    }
    .nav-height{
        height:4.5rem;
        box-shadow: 0px 1px 10px #999;
    }
    .menu-icon{
        display:block;
        position: absolute;
        top:0;
        right:0;
        transform: translate(-100%,60%);
        font-size:1.8rem;
        cursor:pointer;
    }
    .fa-times{
        color:black;
        font-size:2rem;
        margin-top:-5px;
    }
    .nav-links-jellybtn{
        display:block;
        text-align: center;
        margin: auto;
        border-radius: 20px;
        width:9rem;
        height:auto;
        background:#4FD84F;
        text-decoration: none;
        color:white;
        border-color: #4FD84F;
        padding-top: 5px;
        padding-bottom: 8px;
        margin-bottom: 5px;
    }
    .nav-links-loginbtn{
        display:block;
        text-align: center;
        margin: auto;
        border-radius: 20px;
        width:9rem;
        height:auto;
        background:#BDA083; ;
        text-decoration: none;
        color:white;
        border-color: #BDA083;
        padding-top:5px;
        padding-bottom: 9px;
    }
} */